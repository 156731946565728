<template>
  <router-link :to="`/project/` + pid">
    <div class="project-item">
      <div
        class="project-item-banner"
        :style="`background-image:url(` + image + `)`"
      >
        <div class="project-item-progress">
          <div
            class="project-item-progress-status"
            :style="`width:` + progress + `%`"
          ></div>
        </div>
      </div>
      <div class="project-item-info">
        <van-row>
          <van-col span="16">
            <div class="project-item-info-left">
              <p class="project-item-info-left-title">{{ title }}</p>
              <p class="project-item-info-left-desc">
                <svg-icon icon-class="message" /> {{ description }}
              </p>
            </div>
          </van-col>
          <van-col span="8">
            <div class="project-item-info-right">
              <table>
                <tr>
                  <td>
                    <van-tag type="primary" color="red">{{ status }}</van-tag>
                    <van-tag type="primary" color="red">{{ category }}</van-tag>
                  </td>
                </tr>
                <tr>
                  <td>
                    <svg-icon icon-class="aim" /> 累计筹款: {{ amountStr }}
                  </td>
                </tr>
                <tr>
                  <td>
                    <svg-icon icon-class="member" /> 参与次数: {{ member }}
                  </td>
                </tr>
              </table>
            </div>
          </van-col>
        </van-row>
      </div>
    </div>
  </router-link>
</template>

<script>
import { unitConvert } from '@/utils/money'
export default {
  props: {
    pid: Number,
    image: String,
    progress: Number,
    title: String,
    description: String,
    status: String,
    amount: Number,
    member: Number,
    category: String
  },
  mounted () {
    this.amountStr = unitConvert(this.amount)
  },
  data () {
    return {
      amountStr: null
    }
  }
}
</script>

<style>
.project-item {
  width: 100%;
  height: 14rem;
  margin-bottom: 0.6rem;
  background-color: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0 0.02rem 0.08rem 0 rgba(34, 42, 63, 0.1);
}

.project-item-banner {
  width: 100%;
  height: 10rem;
  background-size: cover;
  position: relative;
}
.project-item-progress {
  height: 0.2rem;
  background-color: rgba(255, 255, 255, 0.4);
  position: relative;
  top: 9.8rem;
}
.project-item-progress-status {
  float: left;
  height: 0.2rem;
  background-color: red;
}

.project-item-info {
  height: 4rem;
}

.project-item-info-left {
  padding: 0.3rem 1rem;
}
.project-item-info-left p {
  text-align: left;
  margin: 0;
}

.project-item-info-left-title {
  font-size: 1.2rem;
  display: inline-block;
  width: 99%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.project-item-info-left-desc {
  font-size: 0.7rem;
  line-height: 1.6rem;
}
.project-item-info-right table {
  width: 100%;
  font-size: 0.7rem;
}
</style>

function unitConvert(num) {
  if (num == 0) {
    return 0
  }
  num = parseInt(num)
  var moneyUnits = ['元', '万元', '亿元', '万亿']
  var dividend = 10000
  var curentNum = num
  // 转换数字
  var curentUnit = moneyUnits[0]
  // 转换单位
  for (var i = 0; i < 4; i++) {
    curentUnit = moneyUnits[i]
    if (strNumSize(curentNum) < 5) {
      break
    }
    curentNum = curentNum / dividend
  }
  var m = { num: 0, unit: '' }
  m.num = parseInt(curentNum)
  m.unit = curentUnit
  return m.num + m.unit
}

function strNumSize(tempNum) {
  var stringNum = tempNum.toString()
  var index = stringNum.indexOf('.')
  var newNum = stringNum
  if (index != -1) {
    newNum = stringNum.substring(0, index)
  }
  return newNum.length
}

function formatNumber(num) {
  let numstr = (num.toString().indexOf('.') !== -1) ? num.toLocaleString() : num.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
  return numstr;
}

module.exports = {
  unitConvert: unitConvert,
  formatNumber: formatNumber,
}

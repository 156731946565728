<template>
  <div class="page-container">
    <form action=".">
      <van-search
        v-model="searchKey"
        placeholder="请输入关键词"
        @search="handleSearch"
        @focus="openSearchBox"
      />
    </form>

    <div class="search-guide-box" v-if="isSearching">
      <div class="search-control">
        <van-row>
          <van-col span="5" offset="3"
            ><van-button
              style="width: 100% !important"
              size="small"
              type="warning"
              @click="handleSearch"
              >搜索</van-button
            ></van-col
          >
          <van-col span="5" offset="1"
            ><van-button
              style="width: 100% !important"
              size="small"
              type="primary"
              plain
              @click="resetSearch"
              >重置</van-button
            ></van-col
          >
          <van-col span="5" offset="1"
            ><van-button
              style="width: 100% !important"
              size="small"
              @click="closeSearchBox"
              >关闭</van-button
            ></van-col
          >
        </van-row>
        <van-row>
          <van-col span="3" offset="1">
            <p class="search-button-line search-line-title">
              项目分类
            </p></van-col
          >
          <van-col span="19" offset="1"
            ><p class="search-button-line">
              <van-tag
                class="search-line-button"
                v-for="(category, cidx) in categoryList"
                :key="cidx"
                plain
                type="primary"
                @click="handleButtonSelected(category)"
                >{{ category }}</van-tag
              >
            </p></van-col
          >
        </van-row>

        <van-row>
          <van-col span="3" offset="1">
            <p class="search-button-line search-line-title">
              搜索历史
            </p></van-col
          >
          <van-col span="19" offset="1"
            ><p class="search-button-line">
              <van-tag
                class="search-line-button"
                v-for="(record, sidx) in searchRecord"
                :key="sidx"
                plain
                type="primary"
                @click="handleButtonSelected(record)"
                >{{ record }}</van-tag
              >
              <van-tag
                class="search-line-button"
                type="warning"
                plain
                @click="clearSearchRecord"
                >清空记录</van-tag
              >
            </p></van-col
          >
        </van-row>
      </div>
      <div class="close-search-frame" @click="closeSearchBox"></div>
    </div>

    <div class="list-loading-box" v-if="listLoading">
      <van-loading size="40px">加载中...</van-loading>
    </div>
    <template v-else>
      <div class="project-list" v-if="showProjectList.length > 0">
        <project-item
          v-for="(item, idx) in showProjectList"
          :key="idx"
          :pid="item.id"
          :image="item.image"
          :title="item.title"
          :progress="item.progress"
          :description="item.description"
          :status="item.status"
          :amount="item.amount"
          :member="item.member"
          :category="item.category"
        />
      </div>
      <van-empty v-else description="暂无数据" />
    </template>

    <sdut-banner />
    <tabbar />
  </div>
</template>
<script>
import Tabbar from "@/components/Tabbar";
import SdutBanner from "@/components/SdutBanner";
import ProjectItem from "@/components/ProjectItem";

import { getProjectList } from "@/api/project";
import { getStorage, setStorage } from "@/utils/storage";

export default {
  name: "ProjectPage",
  components: { Tabbar, ProjectItem, SdutBanner },
  data() {
    return {
      searchKey: null,
      projectList: [],
      listLoading: true,
      isSearching: false,
      showProjectList: [],
      categoryList: [],
      searchRecord: [],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      getProjectList(this.searchKey).then((response) => {
        response.forEach((item) => {
          let project = item;
          project.search =
            item.title + item.category + item.status + item.description;
          this.projectList.push(project);
          if (this.categoryList.indexOf(item.category) === -1) {
            this.categoryList.push(item.category);
          }
        });
        this.listLoading = false;
        this.showProjectList = [...this.projectList];
      });
    },
    handleSearch() {
      this.listLoading = true;
      if (this.searchKey === null) {
        this.showProjectList = [...this.projectList];
      } else {
        this.showProjectList = [];
        this.projectList.forEach((item) => {
          if (item.search.indexOf(this.searchKey) != -1) {
            this.showProjectList.push(item);
          }
        });
        this.updateSearchRecord(this.searchKey);
      }
      this.listLoading = false;

      this.closeSearchBox();
    },
    resetSearch() {
      this.searchKey = null;
      this.handleSearch();
      this.closeSearchBox();
    },
    openSearchBox() {
      document.documentElement.style.overflowY = "hidden";
      this.isSearching = true;
      this.searchRecord = this.getSearchRecord();
    },
    closeSearchBox() {
      document.documentElement.style.overflowY = "auto";
      this.isSearching = false;
    },
    handleButtonSelected(key) {
      this.searchKey = key;
      this.handleSearch();
    },
    getSearchRecord() {
      let record = [];
      if (getStorage("searchrecord")) {
        record = JSON.parse(getStorage("searchrecord"));
      } else {
        setStorage("searchrecord", JSON.stringify([]));
      }
      return record;
    },
    updateSearchRecord(r) {
      console.log(r)
      if (r === '') {
        return;
      }
      if (getStorage("searchrecord")) {
        let record = JSON.parse(getStorage("searchrecord"));
        if (record.indexOf(r) == -1) {
          record.unshift(r);
        }
        record = record.slice(0, 6);
        setStorage("searchrecord", JSON.stringify(record));
      } else {
        setStorage("searchrecord", JSON.stringify([r]));
      }
    },
    clearSearchRecord() {
      setStorage("searchrecord", JSON.stringify([]));
      this.searchRecord = this.getSearchRecord();
    },
  },
};
</script>

<style>
.project-list {
  padding: 0 8px;
}

.list-loading-box {
  text-align: center;
  padding: 1rem;
}

.search-guide-box {
  position: absolute;
  z-index: 9999;
  width: 100%;
  height: 100%;
}

.search-control {
  width: 100%;
  min-height: 6rem;
  background-color: rgba(255, 255, 255, 0.9);
}

.close-search-frame {
  width: 100%;
  height: 100%;
}

.search-button-line {
  margin: 0.5rem 0;
}

.search-line-title {
  font-size: 0.8rem;
  font-weight: bold;
}

.search-line-button {
  margin: 0 5px;
}
</style>
